import React from 'react';
import Link from 'next/link';
import dayjs from 'dayjs';
import { useLazyQuery } from '@apollo/client';
import { Hit, SearchResponse } from '@algolia/client-search';
import { validateString } from 'avilatek-utils';
import {
  CheckIcon,
  CloseIcon,
  DotIcon,
  Select,
  Table,
  TimerIcon,
} from '@spa-cars/ui';
import {
  PaginationInfo,
  Appointment,
  User,
  Location,
  AppointmentStatusEnum,
} from '@spa-cars/models';
import { useAlgolia, useUser } from '../../hooks';
import { GET_APPOINTMENTS_LIST } from '../../graphql/queries';
import { toTitleCase } from '../../lib/titleCase';

// const getStatusType = (statusType: OrderStatusEnum) => {
//   switch (statusType) {
//     case 'initial':
//       return 'Inicial';
//     case 'confirmed':
//       return 'Confirmada';
//     case 'processing':
//       return 'En proceso';
//     case 'closed':
//       return 'Terminada';
//     default:
//       return 'N/A';
//   }
// };
// const getStatusTypeIcon = (statusType: OrderStatusEnum) => {
//   switch (statusType) {
//     case 'initial':
//       return <DotIcon className="h-4 w-4 text-success-300" />;
//     case 'confirmed':
//       return <CheckIcon className="h-4 w-4 text-success-300" />;
//     case 'processing':
//       return <TimerIcon className="h-4 w-4 text-neutral-200" />;
//     case 'closed':
//       return <CheckIcon className="h-4 w-4 text-success-300" />;
//     default:
//       return '';
//   }
// };
// LO MISMO PERO CON EL STATUS DE APPOINTMENT
const getStatusType = (
  statusType: AppointmentStatusEnum,
  date: Date,
  appointment: Appointment
) => {
  switch (statusType) {
    case 'not_started':
      if (dayjs(date).isBefore(dayjs())) return 'Vencida';
      if (appointment.order.paymentStatus === 'in_progress')
        return 'Pendiente de pago';
      return 'Pendiente';
    case 'in_progress':
      return 'En proceso';
    case 'completed':
      return 'Completada';
    case 'cancelled':
      return 'Cancelada';
    default:
      return 'N/A';
  }
};
const getStatusTypeIcon = (statusType: AppointmentStatusEnum, date: Date) => {
  switch (statusType) {
    case 'not_started':
      if (dayjs(date).isBefore(dayjs())) {
        return <TimerIcon className="h-4 w-4 text-danger-200" />;
      }
      return <DotIcon className="h-4 w-4 text-success-300" />;
    case 'in_progress':
      return <TimerIcon className="h-4 w-4 text-neutral-200" />;
    case 'completed':
      return <CheckIcon className="h-4 w-4 text-success-300" />;
    case 'cancelled':
      return <CloseIcon className="h-4 w-4 text-danger-200" />;
    default:
      return '';
  }
};
const buildTableData = (
  appointments: Array<Appointment | Hit<Appointment>> = []
) =>
  appointments.map((appointment) => ({
    _id: appointment?.order?._id ?? (appointment?.order as any)?.id,
    location: appointment?.location?.name,
    user: toTitleCase(
      `${(appointment?.user as User)?.firstName ?? ''} ${
        (appointment?.user as User)?.lastName ?? ''
      }`
    ),
    date: dayjs(appointment?.date).format('DD/MM/YYYY') ?? 'N/A',
    createdAt: dayjs(appointment?.createdAt).format('DD/MM/YYYY') ?? 'N/A',
    total: `$${(appointment?.order?.total ?? 0)?.toFixed(2)}` ?? 'N/A',
    status: (
      <p className="flex gap-[10px] items-center">
        {getStatusTypeIcon(appointment?.status, appointment?.date)}
        {getStatusType(appointment?.status, appointment?.date, appointment)}
      </p>
    ),
    reservationNumber: appointment?.reservationNumber ?? 'N/A',
  }));

interface PaginationDataProps {
  items: Array<Appointment>;
  count: number;
  pageInfo: PaginationInfo;
}

export default function OrderTable() {
  const searchOrder = useAlgolia();
  const [user] = useUser();
  const searchIndex = searchOrder.initIndex('appointments');
  const [appointmentStatus, setAppointmentStatus] = React.useState('none');
  const [searchResult, setSearchResult] =
    React.useState<Hit<Appointment>[]>(null);
  const [paginationData, setPaginationData] = React.useState<
    PaginationDataProps | SearchResponse<Appointment>
  >(null);
  const [perPage, setPerPage] = React.useState(10);
  const [searchInput, setSearchInput] = React.useState(''); // guarda lo que el usuario ingresa en el input de busqueda
  const [loadingSearch, setLoadingSearch] = React.useState(false);

  const [loadAppointments, { data, loading, error, fetchMore }] = useLazyQuery<{
    appointmentPagination: {
      items: Array<Appointment>;
      count: number;
      pageInfo: PaginationInfo;
    };
  }>(GET_APPOINTMENTS_LIST);

  const queryVariables = {
    perPage: perPage ?? 10,
    sort: 'DATE_DESC',
    filter: {
      show: true,
      location:
        user?.userType === 'admin'
          ? (user?.location as Location)?._id
          : undefined,
      active: true,
      status: appointmentStatus === 'none' ? undefined : appointmentStatus,
    },
  };

  const handleGoToPage = (value) => {
    if (validateString(searchInput)) {
      searchAppointments(searchInput, value - 1); // ir a la siguiente pagina de la busqueda de algolia
    } else {
      loadAppointments({
        variables: {
          page: value ?? 1,
          ...queryVariables,
        },
        fetchPolicy: 'cache-and-network',
      }); // ir a la siguiente pagina del query
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <p className="flex justify-between items-center">
            CÓDIGO
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),

        accessor: 'reservationNumber',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            CLIENTE
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),

        accessor: 'user',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            PUNTO DE SERVICIO
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),

        accessor: 'location',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            Fecha de la reserva
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'date',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            Creación
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'createdAt',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            TOTAL
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'total',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            ESTATUS
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'status',
      },
    ],
    []
  );

  const filterResults = React.useCallback(
    (results: SearchResponse<Appointment>) =>
      results?.hits?.filter(
        (aa) => aa?.order && aa?.order?.paymentStatus !== 'in_progress'
      ) ?? [],
    []
  );

  const searchAppointments = async (value: string, page_: number) => {
    try {
      if (!validateString(value) || value === '') {
        loadAppointments({
          variables: {
            page: 1,
            ...queryVariables,
          },
          fetchPolicy: 'cache-and-network',
        });
        setPaginationData(data?.appointmentPagination);
        setSearchResult([]);
        return;
      }
      setLoadingSearch(true);
      const result = await searchIndex.search<Appointment>(value, {
        filters:
          user?.userType === 'admin'
            ? `location.id:${(user?.location as Location)?._id}`
            : undefined,
        facetFilters: [
          `show:true`,
          `active:true`,
          `status:${appointmentStatus === 'none' ? '' : appointmentStatus}`,
        ],
        page: page_,
        hitsPerPage: perPage ?? 10,
      });
      setLoadingSearch(false);
      setPaginationData(result);
      const resultsFiltered = filterResults(result);
      setSearchResult(resultsFiltered);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearchInput(e.target.value);
    },
    []
  );

  const dataMemo = React.useMemo(
    () =>
      searchResult?.length > 0
        ? buildTableData(searchResult)
        : buildTableData(
            (paginationData as PaginationDataProps)?.items?.filter(
              (aa) => aa?.order
            )
          ) ?? [],
    [paginationData, searchResult]
  );

  React.useEffect(() => {
    if (data) {
      setPaginationData(data?.appointmentPagination);
    }
  }, [data]);

  React.useEffect(() => {
    searchAppointments(searchInput, 0); // para algolia, la paginación empieza en 0
  }, [appointmentStatus, searchInput, perPage]);

  return (
    <div className="flex flex-col gap-4">
      <Select
        name=""
        value={appointmentStatus ?? 'none'}
        // label="Turno"
        onChange={(e) => {
          setAppointmentStatus(e.target.value);
        }}
        className="pr-10 w-fit shadow-[0_2px_5px_rgba(0,0,0,0.25)] focus:shadow-[0_2px_5px_rgba(0,0,0,0.25)] rounded border-none"
      >
        <option value="none">Todos los servicios</option>
        <option key="not_started" value="not_started">
          Pendientes
        </option>
        <option key="in_progress" value="in_progress">
          En proceso
        </option>
        <option key="completed" value="completed">
          Completados
        </option>
        <option key="cancelled" value="cancelled">
          Cancelados
        </option>
      </Select>
      <Table
        columns={columns}
        data={dataMemo}
        perPage={perPage}
        setPerPage={setPerPage}
        error={null}
        loading={loading || loadingSearch}
        pageCount={0}
        header="Historial de servicios"
        pageNumber={
          ((paginationData as SearchResponse<Appointment>)?.page ??
            ((paginationData as PaginationDataProps)?.pageInfo?.currentPage ??
              1) - 1) + 1
        } //  Si la paginacion viene de algolia, se le suma 1 para que coincida con el numero de pagina que viene de mongoose
        addButtonText="Servicio"
        addButtonPath="/app/walkin"
        handleGoToPage={handleGoToPage}
        results={
          (paginationData as SearchResponse<Appointment>)?.nbHits ??
          (paginationData as PaginationDataProps)?.count
        }
        path="app/orders"
        link={Link}
        onChangeSearch={onChangeSearch}
      />
    </div>
  );
}
